<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent mb-3">
          <h1 v-if="companyId" class="headline">{{ $t('empresa.edit') }}</h1>
          <h1 v-if="!companyId" class="headline">{{ $t('empresa.create') }}</h1>
          <v-spacer />
          <v-chip v-for="(item, index) in empresa.estados" :key="index" label outlined :color="item.color">{{
            item.label
          }}</v-chip>

          <importCsv
            :on-max-file-size="handleOnMaxFileSize"
            :on-csv-file-is-uploaded="handleOnCsvFileIsUploaded"
            :on-csv-file-is-uploading="handleOnCsvFileIsUploading"
            :on-error="handleOnError"
            :company-id="empresa.id"
            :user-id="authUser.id"
          />
        </v-toolbar>

        <v-card>
          <div v-if="loading" class="pa-5 text-center">
            <v-progress-circular width="2" color="primary" indeterminate />
          </div>

          <v-card v-if="!loading" flat class="mb-5">
            <v-card-text>
              <form>
                <v-row>
                  <v-col cols="12" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.nombre"
                      dense
                      filled
                      outlined
                      :label="$t('common.name')"
                      type="text"
                      hide-details
                      required
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.email_admin"
                      dense
                      filled
                      outlined
                      :label="$t('empresa.email_admin')"
                      type="mail"
                      hide-details
                      required
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.email_notificacion"
                      dense
                      filled
                      outlined
                      :label="$t('empresa.email_noty')"
                      type="mail"
                      hide-details
                      required
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.publicEmail"
                      dense
                      filled
                      outlined
                      :label="$t('empresa.public_email')"
                      type="mail"
                      hide-details
                      required
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.phone"
                      dense
                      filled
                      outlined
                      :label="$t('empresa.public_phone')"
                      type="text"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.licenses_num"
                      dense
                      filled
                      outlined
                      :label="$t('empresa.licenses_num')"
                      type="licenses_num"
                      hide-details
                      required
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.address"
                      filled
                      dense
                      outlined
                      hide-details
                      :label="$t('common.address')"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.postalCode"
                      filled
                      dense
                      outlined
                      hide-details
                      :label="$t('common.cp')"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.city"
                      filled
                      dense
                      outlined
                      hide-details
                      :label="$t('common.location')"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="empresa.region"
                      filled
                      dense
                      outlined
                      hide-details
                      :label="$t('common.province')"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-select
                      v-model="empresa.countryCode"
                      filled
                      dense
                      outlined
                      hide-details
                      :items="countries"
                      :label="$t('common.country')"
                      item-text="name"
                      item-value="iso"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" xs="12">
                    <v-textarea
                      v-model="empresa.terms_conditions_signup"
                      filled
                      dense
                      hide-details
                      outlined
                      rows="3"
                      :label="$t('common.term_and_conditions_signup')"
                    />
                  </v-col>
                  <v-col cols="12" md="12" sm="12" xs="12">
                    <v-card outlined class="grey lighten-5 px-5">
                      <v-row>
                        <v-col cols="12" sm="6" lg="4" md="4" xs="12">
                          <v-switch
                            v-model="empresa.sharingPatients"
                            inset
                            :label="$t('empresa.share_patients')"
                            color="success"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" lg="4" md="4" xs="12">
                          <v-switch
                            v-model="empresa.sharingReceptionists"
                            inset
                            :label="$t('empresa.share_receptionists')"
                            color="success"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" lg="4" md="4" xs="12">
                          <v-switch
                            v-model="empresa.hasAnesthetist"
                            inset
                            :label="$t('empresa.has_anesthetist')"
                            color="success"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" lg="4" md="4" xs="12">
                          <v-switch
                            v-model="empresa.payment_module"
                            inset
                            :label="$t('empresa.payment_module')"
                            color="success"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" lg="4" md="4" xs="12">
                          <v-switch
                            v-model="empresa.is_collective"
                            inset
                            :label="$t('empresa.is_collective')"
                            color="success"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" lg="4" md="4" xs="12">
                          <v-switch
                            v-model="empresa.is_health_personnel"
                            inset
                            :label="$t('empresa.is_health_personnel')"
                            color="success"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" lg="4" md="4" xs="12">
                          <v-switch
                            v-model="empresa.interconsultation"
                            inset
                            :label="$t('empresa.have_interconsultation')"
                            color="success"
                          />
                        </v-col>
                        <v-col v-if="empresa.is_health_personnel" cols="12" sm="6" lg="4" md="4" xs="12">
                          <v-switch
                            v-model="empresa.informed_consent"
                            inset
                            :label="$t('empresa.informedConsent')"
                            color="success"
                          />
                        </v-col>
                        <v-col v-if="empresa.is_health_personnel" cols="12" sm="6" lg="4" md="4" xs="12">
                          <v-switch
                            v-model="empresa.printPrescriptions"
                            inset
                            :label="$t('empresa.printPrescriptions')"
                            color="success"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" xs="12">
                    <template v-if="statusInitialPaymentModule !== empresa.payment_module && !empresa.payment_module">
                      <v-alert color="warning" icon="mdi-alert" :value="true">{{
                        $t('empresa.service_of_user_change_to_free')
                      }}</v-alert>
                    </template>
                    <v-row v-if="empresa.payment_module">
                      <v-col cols="12" sm="6" lg="6">
                        <v-switch
                          v-model="empresa.paymentSettings.single_tpv_account"
                          class="d-inline-block"
                          inset
                          :label="
                            empresa.paymentSettings && empresa.paymentSettings.single_tpv_account
                              ? $t('empresa.single_tpv_account')
                              : $t('empresa.single_tpv_account')
                          "
                          color="success"
                        />
                      </v-col>
                      <v-col cols="12" xs="3" sm="3">
                        <v-select
                          v-model="empresa.paymentSettings.payment_type"
                          :items="payment_types"
                          :label="$t('empresa.payment_type')"
                          auto
                          outlined
                          dense
                          required
                          filled
                          item-text="name"
                          item-value="id"
                        />
                      </v-col>
                      <v-col v-if="empresa.paymentSettings.payment_type === 1" xs="6" sm="6">
                        <v-select
                          v-model="empresa.paymentSettings.fee_type"
                          outlined
                          :items="fee_types"
                          :label="$t('empresa.fee_type')"
                          auto
                          required
                          filled
                          dense
                          item-text="name"
                          item-value="id"
                        />
                      </v-col>
                      <v-col v-if="empresa.paymentSettings.payment_type === 1" xs="6" sm="6">
                        <v-text-field
                          v-model="empresa.paymentSettings.fee_value"
                          outlined
                          filled
                          dense
                          class="mr-2"
                          :label="
                            empresa.paymentSettings.fee_type === 1
                              ? $t('empresa.fee_value') + ' (%)'
                              : $t('empresa.fee_value') + ' (€)'
                          "
                          type="number"
                          step="0.01"
                          min="0"
                          max="100"
                          required
                          :append-icon="empresa.paymentSettings.fee_type === 0 ? 'mdi-currency-eur' : ''"
                        />
                      </v-col>
                      <v-col
                        v-if="empresa.paymentSettings.payment_type === 1 && empresa.paymentSettings.fee_type === 1"
                        xs="6"
                        sm="6"
                      >
                        <v-text-field
                          v-model="empresa.paymentSettings.fee_min"
                          outlined
                          filled
                          dense
                          :label="$t('empresa.fee_min')"
                          type="number"
                          step="0.01"
                          min="0"
                          max="100"
                          required
                          append-icon="mdi-currency-eur"
                        />
                      </v-col>
                      <v-col
                        v-if="empresa.paymentSettings.payment_type === 1 && empresa.paymentSettings.fee_type === 1"
                        xs="6"
                        sm="6"
                      >
                        <v-text-field
                          v-model="empresa.paymentSettings.fee_max"
                          outlined
                          filled
                          dense
                          :label="$t('empresa.fee_max')"
                          type="number"
                          step="0.01"
                          min="0"
                          max="100"
                          required
                          append-icon="mdi-currency-eur"
                        />
                      </v-col>

                      <v-col v-if="empresa.paymentSettings.payment_type === 2" xs="3" sm="3">
                        <v-select
                          v-model="empresa.premiumPaymentTypes"
                          outlined
                          filled
                          dense
                          :items="empresa.paymentSettings.premiumPaymentTypes"
                          :label="$t('empresa.premium_payment_types')"
                          auto
                          multiple
                          required
                          item-text="name"
                          item-value="id"
                          class="ml-2"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="
                      statusInitialPaymentModule === empresa.payment_module && !empresa.payment_module && addNewUser
                    "
                    sm12
                  >
                    <v-alert color="warning" icon="mdi-alert" :value="true">
                      {{ $t('empresa.service_of_user_change_to_free_add_company') }}
                    </v-alert>
                  </v-col>
                  <v-col sm="12" lg="12">
                    <v-autocomplete
                      v-model="empresa.usuarios"
                      outlined
                      filled
                      dense
                      :label="$t('empresa.users')"
                      :items="usuarios"
                      chips
                      item-text="fullName"
                      item-value="id"
                      multiple
                      @input="addUserToInput"
                    >
                      <template slot="selection" slot-scope="data">
                        <v-chip
                          :input-value="data.selected"
                          close
                          small
                          class="chip--select-multi"
                          @click:close="removeUserToInput(data.item.id)"
                          @click="$router.push('/professionals/' + data.item.id)"
                        >
                          {{ data.item.nombre + ' ' + data.item.apellidos }}
                        </v-chip>
                      </template>
                      <template slot="item" slot-scope="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item" />
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.nombre + ' ' + data.item.apellidos }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <div class="text-center mb-5">
                  <v-btn depressed :disabled="disabledButton" color="primary" @click="editarEmpresa()">{{
                    $t('empresa.guardar_empresa')
                  }}</v-btn>
                </div>
              </form>
            </v-card-text>
          </v-card>
          <button v-show="false" v-shortkey="['ctrl', 'alt', 'o']" @shortkey="showComponentsHidden()" />
        </v-card>
        <permissions
          v-if="showPermissions"
          :config-by-admin-permissions="configByAdminPermissions"
          :show-additional-settings="showAdditionalSettings"
          :permissions="permissions"
          :default-permissions="defaultPermissions"
        />
        <customStyles v-if="showAdditionalSettings" :styles="styles" />
      </v-card>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon>
      &nbsp;{{ alerta.texto }}
    </v-snackbar>
  </v-container>
</template>

<script>
import Permissions from '@/components/ehealth/ajustes/Permissions.vue';
import CustomStyles from '@/components/ehealth/ajustes/Customstyles.vue';
import ImportCsv from '@/components/common/ImportCsv.vue';
import environment from '@/environment';
import { nameEnv, getCompanyUrl, editCompanyUrl, getHeader } from '../config/config';
import { getCountries } from "@/utils/countries";

const preferredCountries = ['ES', 'FR', 'PT'];

export default {
  components: {
    Permissions,
    CustomStyles,
    ImportCsv,
  },
  data: function() {
    return {
      companyId: null,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: true,
      disabledButton: true,
      statusInitialPaymentModule: false,
      addNewUser: false,
      snackbar: false,
      timeout: 5000,
      mode: '',
      empresa: {
        name: '',
        paymentSettings: {},
        terms_conditions_signup: '',
        publicEmail: null,
        address: null,
        city: null,
        province: null,
        postalCode: null,
        countryCode: null,
      },
      usuarios: [],
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
      },
      permissions: [],
      showPermissions: false,
      showAdditionalSettings: false,
      defaultPermissions: environment.permissions.global,
      configByAdminPermissions:
        typeof environment.permissions.configByAdmin !== 'undefined' ? environment.permissions.configByAdmin : [],
      styles: {
        theme_color_primary: '',
        theme_color_secondary: '',
        logo: '',
      },
      payment_types: [
        { id: 0, name: 'Premium' },
        { id: 2, name: 'Premium con Límites' },
      ],
      custom_payment_types: [],
      fee_types: [
        { id: 0, name: 'Fijo (€)' },
        { id: 1, name: 'Por Porcentaje' },
      ],
      es_medyola: nameEnv === 'medyola',
      countries: getCountries(this.$i18n.locale),
    };
  },

  mounted() {
    const tieneAcceso =
      this.authUser !== null && (this.authUser.rol === 1 || this.authUser.rol === 4) && !this.es_medyola;
    if (tieneAcceso) {
      this.getDataCompany();
    } else {
      this.$router.push('/dashboard');
    }
    this.companyId = this.$route.params.id;
  },

  created() {
    this.setPreferredCountries();
  },

  methods: {
    setPreferredCountries() {
      const preferred = preferredCountries.slice().reverse();
      for (const countryCode of preferred) {
        this.countries.sort(function(x, y) {
          return x.iso === countryCode ? -1 : y.iso === countryCode ? 1 : 0;
        });
      }
    },

    getDataCompany() {
      this.loading = true;
      this.$http
        .post(getCompanyUrl, { user_id: this.authUser.id, empresa_id: this.$route.params.id }, { headers: getHeader() })
        .then(
          response => {
            if (response.status === 200 && response.data && response.data.empresa) {
              this.empresa = response.data.empresa;
              this.statusInitialPaymentModule = this.empresa.payment_module;
              this.usuarios = response.data.usuarios.map(user => {
                return {
                  ...user,
                  fullName: `${user.nombre} ${user.apellidos}`,
                };
              });
              this.loading = false;
              this.disabledButton = false;
              this.setDataPermissions(response.data.permissions);
              this.showPermissions = true;
              if (response.data.empresa.theme_color_primary) {
                this.styles.theme_color_primary = response.data.empresa.theme_color_primary;
              }

              if (response.data.empresa.theme_color_secondary) {
                this.styles.theme_color_secondary = response.data.empresa.theme_color_secondary;
              }

              if (response.data.empresa.logo) {
                this.styles.logo = response.data.empresa.logo;
              }
            }
          },
          () => {
            this.$router.push('/usuarios');
          }
        );
    },

    handleOnMaxFileSize(maxSize) {
      this.alerta.icono = 'mdi-alert-circle';
      this.alerta.texto = this.$t('errors.file_max_upload', { tam: maxSize });
      this.alerta.color = 'error';
      this.snackbar = true;
      this.loading = false;
    },

    setDataPermissions(permissionsDB) {
      this.permissions = JSON.parse(JSON.stringify(this.defaultPermissions));

      if (permissionsDB !== null) {
        Object.keys(permissionsDB).forEach(permission => {
          this.permissions[permission] = permissionsDB[permission];
        });
      }
    },

    handleOnCsvFileIsUploading() {
      this.loading = true;
    },

    handleOnCsvFileIsUploaded() {
      this.alerta.texto = this.$t('documentos.csv_uploaded');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-check';
      this.getDataCompany();
      this.snackbar = true;
      this.loading = false;
    },

    handleOnError() {
      this.alerta.texto = this.$t('errors.try_again');
      this.alerta.color = 'error';
      this.snackbar = true;
      this.loading = false;
    },

    showComponentsHidden() {
      this.showAdditionalSettings = true;
      this.payment_types.push({ id: 1, name: 'Freemium' });
    },

    validarFormulario() {
      this.alerta.texto = '';
      this.snackbar = false;
      const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (this.empresa.nombre.length < 3) {
        this.alerta.texto = this.$t('empresa.nombre_required');
      }

      if (
        (this.empresa.email_notificacion !== '' &&
          this.empresa.email_notificacion !== null &&
          !regex.test(this.empresa.email_notificacion)) ||
        (this.empresa.email_admin !== '' && !regex.test(this.empresa.email_admin))
      ) {
        this.alerta.texto = this.$t('errors.email_invalid');
      }

      return this.alerta.texto !== '' ? false : true;
    },

    editarEmpresa() {
      this.alerta.color = 'warning';
      this.alerta.icono = 'mdi-alert-circle';
      const es_valido = this.validarFormulario();
      if (es_valido) {
        this.disabledButton = true;
        this.$http
          .post(editCompanyUrl, { user_id: this.authUser.id, empresa: this.empresa }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data) {
              if (response.data.estado === 1) {
                this.alerta.texto = this.$t('empresa.edit_empresa_success');
                this.alerta.color = 'success';
                this.alerta.icono = 'mdi-check';
              } else {
                this.alerta.texto = this.$t('errors.try_again');
              }
            } else {
              this.alerta.texto = this.$t('errors.try_again');
            }
            this.loading = false;
            this.snackbar = true;
            this.disabledButton = false;
          })
          .catch(e => {
            this.alerta.color = 'error';
            if (e.data && e.data.error) {
              this.alerta.texto = this.$t(e.data.error);
            } else {
              this.alerta.texto = this.$t('errors.try_again');
            }
            this.loading = false;
            this.snackbar = true;
            this.disabledButton = false;
          });
      } else {
        this.snackbar = true;
      }
    },
    removeUserToInput(item) {
      this.empresa.usuarios.splice(this.empresa.usuarios.indexOf(item), 1);
      this.empresa.usuarios = [...this.empresa.usuarios];
    },

    addUserToInput() {
      this.addNewUser = true;
    },
  },
};
</script>
<style scoped lang="css">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.loading_empresa {
  margin-top: 30px;
  text-align: center;
}
.form_datos_personales,
.form_historial_clinico {
  padding: 10px;
}
.label_genero {
  margin-bottom: 0px;
}
.radio_genero {
  margin-top: 3px;
}
.md-card-profile {
  width: 96%;
  margin: -50px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.avatar {
  z-index: 1;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.form_marketplace {
  margin-top: 40px;
}
.foto_empresa:hover {
  box-shadow: 0 10px 30px -12px #2196f369, 0 4px 25px 0 #2196f370, 0 8px 10px -5px rgba(33, 150, 243, 0.3);
}
.card_mensajeria {
  background: linear-gradient(to right top, #920837, #f16d6d);
}
.card_videoconsulta {
  background: linear-gradient(to right top, #0d4080, #98caf1);
}
.card_packs {
  background: linear-gradient(to right top, #af8e27, #f1e79f);
}
</style>
