var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"permissions"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('permissions.conf_permissions'))+" ")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-card',{staticClass:"grey lighten-5 px-5",attrs:{"outlined":""}},[_c('v-row',[_vm._l((_vm.generalPermissions),function(value,index){return [(
                _vm.configByAdminPermissions.length === 0 ||
                  _vm.configByAdminPermissions.indexOf(index) > -1 ||
                  _vm.showAdditionalSettings
              )?_c('v-col',{key:index,attrs:{"cols":"12","xs":"12","sm":"12","md":"3"}},[_c('v-switch',{attrs:{"inset":"","label":_vm.titleTranslated(index),"color":"success"},model:{value:(_vm.permissions[index]),callback:function ($$v) {_vm.$set(_vm.permissions, index, $$v)},expression:"permissions[index]"}})],1):_vm._e()]})],2)],1)],1)],1),(_vm.prescriptionsPermissions)?_c('v-card',{staticClass:"mt-5"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('permissions.prescriptionsPermissions'))+" ")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',_vm._l((_vm.prescriptionsPermissions),function(value,parentIndex){return _c('v-col',{attrs:{"xs":"12","sm":"12","md":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"grey lighten-4"},[_vm._v(" "+_vm._s(_vm.titleTranslated(parentIndex))+" ")]),_c('v-card-text',[_c('v-row',[_vm._l((_vm.prescriptionsPermissions[parentIndex]),function(value,index){return [(
                      _vm.configByAdminPermissions.length === 0 ||
                        _vm.configByAdminPermissions.indexOf(index) > -1 ||
                        _vm.showAdditionalSettings
                    )?_c('v-col',{key:index,attrs:{"cols":"12","xs":"12","sm":"12","md":12 / Object.keys(_vm.prescriptionsPermissions[parentIndex]).length}},[_c('v-switch',{attrs:{"inset":"","label":_vm.titleTranslated(index),"color":"success"},model:{value:(_vm.permissions[index]),callback:function ($$v) {_vm.$set(_vm.permissions, index, $$v)},expression:"permissions[index]"}})],1):_vm._e()]})],2)],1)],1)],1)}),1)],1)],1):_vm._e(),(_vm.personalDoctorPermissions)?_c('v-card',{staticClass:"mt-5"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('permissions.personalDoctor'))+" ")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-card',{staticClass:"grey lighten-5 px-5",attrs:{"outlined":""}},[_c('v-row',[_vm._l((_vm.personalDoctorPermissions),function(value,index){return [(
                _vm.configByAdminPermissions.length === 0 ||
                  _vm.configByAdminPermissions.indexOf(index) > -1 ||
                  _vm.showAdditionalSettings
              )?_c('v-col',{key:index,attrs:{"cols":"12","xs":"12","sm":"12","md":"3"}},[_c('v-switch',{attrs:{"inset":"","label":_vm.titleTranslated(index),"color":"success"},model:{value:(_vm.permissions['personalDoctor'][index]),callback:function ($$v) {_vm.$set(_vm.permissions['personalDoctor'], index, $$v)},expression:"permissions['personalDoctor'][index]"}})],1):_vm._e()]})],2)],1)],1)],1):_vm._e(),_c('div',{staticClass:"text-center pt-5 mt-5 mb-5"},[_c('v-btn',{attrs:{"loading":_vm.isSaving,"depressed":"","disabled":_vm.disabledButton || _vm.isSaving,"color":"primary"},on:{"click":function($event){return _vm.savePermissions()}}},[_vm._v(_vm._s(_vm.$t('permissions.save_permissions')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }