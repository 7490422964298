<template>
  <div id="customStyles">
    <v-card>
      <v-toolbar :color="'primary'" dark flat dense cad>
        <v-toolbar-title class="subheading"> {{ $t('permissions.conf_styles') }} </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-divider />

      <v-card-text>
        <v-layout wrap>
          <v-flex xs5>
            <b>Color principal</b><br /><br />
            <input type="color" :value="styles.theme_color_primary" @change="setValue($event, 'theme_color_primary')" />
            <span>&nbsp;&nbsp; {{ styles.theme_color_primary }}</span>
          </v-flex>

          <v-flex xs5>
            <b>Color secundario:</b><br /><br />
            <input
              type="color"
              :value="styles.theme_color_secondary"
              @change="setValue($event, 'theme_color_secondary')"
            />
            <span>&nbsp;&nbsp; {{ styles.theme_color_secondary }}</span>
          </v-flex>

          <v-flex xs12 s12 md12>
            <br />
            <v-flex sm12 lg12 xs12>
              <v-text-field
                class="mr-2"
                :label="'URL Logo:'"
                type="url"
                :value="styles.logo"
                append-icon="mdi-camera"
                required
                @change="setValue($event, 'logo')"
              />
            </v-flex>
          </v-flex>
        </v-layout>
        <div class="text-center text-sm-right">
          <v-btn class="white--text" :disabled="disabledButton" color="blue" @click="saveStyles()">
            {{ $t('permissions.save_styles') }}
          </v-btn>
        </div>
      </v-card-text>
      <v-divider />
    </v-card>

    <!-- Snackbar alerts -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alert.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alert.icon !== ''">{{ alert.icon }}</v-icon
      >&nbsp;{{ alert.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { getHeader, saveCompaniesStyles } from '../../../config/config';

export default {
  name: 'Customstyles',
  props: {
    styles: Object,
  },

  data() {
    return {
      color: '#5CE500',
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      disabledButton: true,
      alert: {
        text: '',
        icon: '',
        color: 'info',
      },
      snackbar: false,
      timeout: 5000,
      mode: '',
    };
  },

  methods: {
    setValue(ev, key) {
      if (key === 'logo') {
        this.styles[key] = ev;
      } else {
        this.styles[key] = ev.target.value;
      }

      if (this.disabledButton) {
        this.disabledButton = false;
      }
    },

    saveStyles() {
      this.$http
        .post(
          saveCompaniesStyles,
          { user_id: this.authUser.id, styles: this.styles, company_id: this.$route.params.id },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data) {
            if (response.data.status === 1) {
              this.showAlert(this.$t('empresa.edit_empresa_success'), 'success', 'mdi-check');
            } else {
              this.showAlert(this.$t('errors.try_again'), 'warning', 'mdi-alert-circle');
            }
          } else {
            this.showAlert(this.$t('errors.try_again'), 'warning', 'mdi-alert-circle');
          }
          this.disabledButton = true;
        });
    },

    showAlert(text, color, icon) {
      this.alert.text = text;
      this.alert.color = color;
      this.alert.icon = icon;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped></style>
